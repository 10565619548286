.removeFilters_background {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.384);
    z-index: 7;
  }
  
  .background_center_removeFilters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15vh;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.09);
    border-radius: 9px;
    padding: 10px;
  }
  
.values-api{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.div_all_itens_api {
  width: 100%;
  overflow-y: auto; 
}


.values-api_response {
  width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 850px) {
  .values-api_response {
    width: calc(100% - 40px);
  }
  .container {
    flex-direction: column !important;
  }
}


@media screen and (min-width: 1000px) {
  .values-api_response {
    width: 1200px;
  }
}

.values-api_response div {
  margin-bottom: 10px;
}

.values-api_response div:last-child {
  margin-bottom: 0;
}

.values-api_response div:nth-child(even) {
  background-color: #f0f0f0;
}

.values-api_response div:nth-child(odd) {
  background-color: #ffffff;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }

.container_engrenagens {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

tr:nth-child(even) td {
  background-color: #f2f2f2;
}

tr:nth-child(odd) td {
  background-color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
}
th {
  background-color: #f2f2f2;
}
@media only screen and (max-width: 600px) {
  table {
    font-size: 12px;
  }
}