.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 2rem;
    width: 150px;
    height: 150px;
}

.background_black{
  background: rgba(0, 0, 0, 0.384);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
}